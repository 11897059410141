const authProfileAthletePageAlertsPtBr = {
  'auth.profileAthletePage.alert.experienceDeletedSuccessfully': 'Experiência deletada com sucesso!',
  'auth.profileAthletePage.alert.profileUpdatedSuccessfully': 'Perfil atualizado com sucesso!',
  'auth.profileAthletePage.alert.videoDeletedSuccessfully': 'Vídeo deletado com sucesso!',
  'auth.profileAthletePage.alert.wantToDeleteTheVideo': 'Deseja realmente excluir o vídeo?',
  'auth.profileAthletePage.alert.proposalSentSuccessfully': 'Proposta enviada com sucesso!',
  'auth.profileAthletePage.alert.wantToDeleteTheExperience': 'Deseja realmente excluir essa experiência?',
};

export const authProfileAthletePagePtBr = {
  'auth.profileAthletePage.title': 'Perfil do Jogador',
  'auth.profileAthletePage.professionalExperience': 'Experiência Profissional',
  'auth.profileAthletePage.cvInPDF': 'Currículo em PDF',
  'auth.profileAthletePage.athleteStatus': 'Status atual do jogador?',
  ...authProfileAthletePageAlertsPtBr,
};
