const authProfileAthletePageAlertsEn = {
  'auth.profileAthletePage.alert.experienceDeletedSuccessfully': 'Experience deleted successfully!',
  'auth.profileAthletePage.alert.profileUpdatedSuccessfully': 'Profile updated successfully!',
  'auth.profileAthletePage.alert.videoDeletedSuccessfully': 'Video deleted successfully!',
  'auth.profileAthletePage.alert.wantToDeleteTheVideo': 'Do you really want to delete the video?',
  'auth.profileAthletePage.alert.proposalSentSuccessfully': 'Proposal sent successfully!',
  'auth.profileAthletePage.alert.wantToDeleteTheExperience': 'Do you really want to delete this experience?',
};

export const authProfileAthletePageEn = {
  'auth.profileAthletePage.title': 'Player Profile',
  'auth.profileAthletePage.professionalExperience': 'Professional Experience',
  'auth.profileAthletePage.cvInPDF': 'Resume in PDF',
  'auth.profileAthletePage.athleteStatus': 'Current player status?',
  ...authProfileAthletePageAlertsEn,
};
