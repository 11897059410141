const authVideosPageAlertsPtBr = {
  'auth.videosPage.wantToDeleteTheVideo': 'Deseja realmente excluir o vídeo?',
  'auth.videosPage.videoAddedSuccessfully': 'Vídeo adicionado com sucesso!',
};

export const authVideosPagePtBr = {
  'auth.videosPage.title': 'Meus Vídeos',
  'auth.videosPage.youtubeVideoLink': 'Link Vídeo do Youtube',
  'auth.videosPage.invalidVideoUrl': 'A url está inválida, tente novamente!',
  ...authVideosPageAlertsPtBr,
};
