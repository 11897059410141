const authWelcomePageUserTypePtBr = {
  'auth.welcomePage.userType.title': 'Olá, {user}',
  'auth.welcomePage.userType.subtitle': 'Aqui é o lugar onde jogadores, agentes e clubes se conectam.',
  'auth.welcomePage.userType.youAre': 'Você é...',
  'auth.welcomePage.userType.option.player': 'Jogador (a)',
};

const authWelcomPagePlayerTypePtBr = {
  'auth.welcomePage.playerType.title': 'Suas Características',

  'auth.welcomePage.playerType.form.youAreAvailable': 'Você está atualmente disponível?',
};

const authWelcomePagePlayerExperienceTypePtBr = {
  'auth.welcomePage.playerExperience.title': 'Suas Experiências Profissionais',
  'auth.welcomePage.playerExperience.subtitle': 'Suas experiências Profissionais, são os clubes onde já jogou',

  'auth.welcomePage.playerExperience.alert.videoLinkIncorrect':
    'O link do Youtube está incorreto! Ele deve estar neste formato: https://www.youtube.com/watch?v=aaaaaaaaaaa',
};

const authWelcomePageManagerTypePtBr = {
  'auth.welcomePage.managerType.title': 'Fale um pouco sobre você',

  'auth.welcomePage.managerType.form.telephone': 'Telefone para contato',
};

export const authWelcomePagePtBr = {
  'auth.welcomePage.title': 'Bem-vindo',
  ...authWelcomePageUserTypePtBr,
  ...authWelcomPagePlayerTypePtBr,
  ...authWelcomePagePlayerExperienceTypePtBr,
  ...authWelcomePageManagerTypePtBr,
};
